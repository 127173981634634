
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';
import arraySort from 'array-sort';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { useWindowSize } from 'vue-window-size';

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: 'kt-datatable',
  emit: ['current-change', 'sort', 'items-per-page-change'],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: 'No matching record found' },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: 'asc' },
    sortLabel: { type: String, default: '' },
    url: { type: String },
  },
  components: {},
  setup(props, { emit }) {
    const data = toRef(props, 'tableData');
    const loadingRef = toRef(props, 'loading');
    const currentSort = ref<string>('');
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const router = useRouter();

    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const { width } = useWindowSize();

    watch(
      () => data.value,
      () => {
        if ('onCurrentChange' in vnodeProps) {
          currentSort.value = label.value + order.value;
        }
      }
    );

    watch(
      () => props.total,
      () => {
        pagination.value.total = props.total;
      }
    );

    onMounted(() => {
      currentSort.value = label.value + order.value;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      return data.value;
    });

    const currentPageChange = (val) => {
      if ('onCurrentChange' in vnodeProps) {
        emit('current-change', val);
      } else {
        pagination.value.page = val;
      }
      reinitializeComponents();
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }

      if ('onSort' in vnodeProps) {
        if (order.value === 'asc') {
          order.value = 'desc';
          emit('sort', { columnName: columnName, order: 'desc' });
        } else {
          order.value = 'asc';
          emit('sort', { columnName: columnName, order: 'asc' });
        }
      } else {
        if (order.value === 'asc') {
          order.value = 'desc';
          arraySort(data.value, columnName, { reverse: false });
        } else {
          order.value = 'asc';
          arraySort(data.value, columnName, { reverse: true });
        }
      }
      currentSort.value = columnName + order.value;
    };

    const setItemsPerPage = (event) => {
      if ('onItemsPerPageChange' in vnodeProps) {
        emit('items-per-page-change', parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      pagination,
      currentPageChange,
      getItems,
      sort,
      currentSort,
      setItemsPerPage,
      data,
      loadingRef,
      width,
      router,
    };
  },
});
