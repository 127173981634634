<template>
  <div
    class="modal fade"
    :id="idProps"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-12">
          <h1 class="text-center">{{ title }}</h1>
          <p class="fs-2 pt-2 text-center">
            {{ message }}
          </p>
          <div class="d-flex justify-content-between pt-4">
            <button
              v-if="!canBack"
              type="button"
              name="cancel"
              class="btn btn-danger px-10"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {{ canBack || 'Cancel' }}
            </button>
            <slot name="cancel"></slot>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    message: {
      type: String,
      default: 'Would you like to confirm your action?',
    },
    idProps: {
      type: String,
      default: 'confirm',
    },
    canBack: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
